import timeTrackerRoute from "@/apps/time-tracker/router/timeTrackerRoute";
import { createRouter, createWebHistory, RouteRecordRaw } from "vue-router";
import PageNotFound from "../apps/authentication/components/PageNotFound.vue";
import {
  decodeRouteToken,
  fetchToken,
  getToken,
  getUserid,
  isLoggedIn,
  setToken,
} from "../services/auth";
import { encodeUrlPath } from "@/helpers/utils";
import { useStore } from "@/store";

const route: Array<RouteRecordRaw> = [
{
    path: "/login",
    name: "login",
    alias: "/login",
    component: () => {
      window.location.href = `${env.VUE_APP_MAIN_PAGE_URL}login`;
      return null; // Adjust the import path accordingly
    },
    meta: { auth: false }
  },
  {
    path: "/time-tracking/:id/time-tracker/clock-it",
    name: "clock-it",
    meta: { auth: true },
    component: () =>
      import(
        /* webpackChunkName: "clock-it" */ "@/apps/time-tracker/components/timeTrackerComponents/ClockIt.vue"
      ),
  },
  {
    path: "/myglobalcomponentsarehere",
    name: "ShowGlobal",
    component: () =>
      import(/* webpackChunkName: "global" */ "@/ui-kit/GlobalComponents.vue"),
  },

  {
    path: "/legacy",
    name: "legacy",
    component: () =>
      (window.location.href = `${
        process.env.VUE_APP_ERP_API
      }/auth?token=${getToken()}`),
    //  import(/* webpackChunkName: "legacy" */ "@/apps/HomePage.vue"),
    meta: { auth: true },
    beforeEnter: (_to, _from, next) => {
      if (isLoggedIn()) {
        window.location.href = `${
          process.env.VUE_APP_ERP_API
        }/auth?token=${getToken()}`;
      } else {
        next();
      }
    },
  },

  {
    path: "/reset-password",
    name: "ResetPassword",
    component: () =>
      import(
        /* webpackChunkName: "about" */ "../apps/authentication/components/ResetPassword.vue"
      ),
  },
  {
    path: "/user-profile",
    name: "user-profile",
    meta: { auth: true },
    component: () =>
      (window.location.href = `/view-employee/${encodeUrlPath(
        String(getUserid())
      )}/personal`),
  },
  {
    path: "/request-loan",
    name: "request-loan",
    meta: { auth: true },
    component: () =>
      (window.location.href = `/view-employee/${encodeUrlPath(
        String(getUserid())
      )}/personal/loans`),
  },

  {
    path: "/:catchAll(.*)*",
    name: "PageNotFound",
    component: PageNotFound,
    meta: { auth: true },
  },

  /** End of Auth routes */
];

const env = process.env;

export const routes = route.concat(timeTrackerRoute);

// Create the router
const router = createRouter({
  history: createWebHistory(),
  routes,
});

router.beforeEach(async (to, _from, next) => {
  document.title = "Clock It";

  if (getToken()) {
    const id = useStore()?.user.user_id;
    if (id) {
      const encodeId = encodeUrlPath(String(id));
      const targetRoute = `/time-tracking/${encodeId}/time-tracker/clock-it`;

       next();

      if (to.path === "/") {

        router.replace(targetRoute); // Use router.replace() instead of next()

      } else {

        next(); // Allow navigation to proceed if already on the target route or if the route doesn't require auth

      }

    } else {

      next("/login"); // Redirect to login if user ID is not found
    }
    return;
  }


  const response = await fetchToken();

  if (response.app_token) {
    const appToken = response.app_token;
    const loginHint = response.login_hint as string;
    
    useStore()?.setUsersHint(loginHint);
    setToken(appToken as string);
    const decodedToken = decodeRouteToken(appToken as string);
    useStore()?.setUser(decodedToken);
    const id = encodeUrlPath(String(decodedToken?.user_id));
    const route = `/time-tracking/${id}/time-tracker/clock-it`;

    router.replace(route);

  } else if (to.meta.auth && !isLoggedIn()) {

    const redirect = `${window.location.protocol}//${window.location.host}`;
    window.location.href = `${env.VUE_APP_SSO}/v2/login?redirect-to=${redirect}`; // to change this to new dashboard after ever service complete new implementation

  } else {
    next();
  }
});

export default router;
